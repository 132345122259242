<template>
  <div id="member-subscription-checkout">
    <v-card>
      <v-card-title class="text-h5">
        <h2>Member Subscription Checkout</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>Kindly check all details before payment</h4></v-card-subtitle
      >

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="StartupLoadingFlag">
          <v-row wrap>
            <v-col cols="12" md="4" v-for="n in 6" :key="n">
              <v-skeleton-loader
                v-bind="attrs"
                type="paragraph"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="!StartupLoadingFlag">
          <v-form ref="form2" v-model="valid1" lazy-validation>
            <v-row wrap>
              <v-col cols="12" md="3">
                <label>*Year</label>
                <h4>{{ JciYearName }}</h4>
              </v-col>
              <v-col cols="12" md="3">
                <label>*LOM </label>
                <h4>{{ Member.LomName }}</h4>
                <h4>{{ Member.ZoneName }}</h4>
              </v-col>
              <v-col cols="12" md="3">
                <label>*President Name</label>
                <h4>{{ Member.MemberName }}</h4>
                <h4>{{ Member.MembershipId }}</h4>
              </v-col>
              <v-col cols="12" md="3">
                <v-img
                  :src="Member.MemberImagePath"
                  :alt="Member.MemberName"
                  lazy-src="/no-image-available.png"
                  max-width="100"
                  max-height="100"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" md="3">
                <label>*Mobile Number</label>
                <h4>{{ Member.PrimaryMobile }}</h4>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>*Email Id</label>
                <h4>{{ Member.EmailId }}</h4>
              </v-col>
              <v-col cols="12" md="3">
                <label>*GST Number </label>
                <h4>{{ GstNumber }}</h4>
              </v-col>
            </v-row>

            <v-container class="py-0" v-if="SearchFlag">
              <v-skeleton-loader
                v-bind="attrs"
                type="table-tbody"
              ></v-skeleton-loader>
            </v-container>

            <v-container class="py-0" v-if="!SearchFlag">
              <!-- <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3>{{ rows.length }} loms found.</h3>
              </v-col>
            </v-row> -->
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <!-- <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search records here"
                  single-line
                  hide-details
                ></v-text-field> -->
                  <br />
                  <v-data-table
                    class="elevation-1"
                    disable-sort
                    disable-filtering
                    v-model="selected"
                    :loading="SearchFlag"
                    loading-text="Loading... Please wait"
                    :headers="tableColumns1"
                    :items="rows"
                    :items-per-page="50"
                    :search="search"
                    item-key="YearwiseContributionId"
                    :single-select="false"
                    :show-select="false"
                    :hide-default-footer="true"
                    :footer-props="{
                      'items-per-page-options': [10, 20, 30, 40, 50],
                    }"
                  >
                    <template v-slot:item.Qty="{ item }">
                      {{ item.Qty }}
                    </template>
                    <template v-slot:item.SubTotal="{ item }">
                      {{ item.SubTotal }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center" cols="12" md="12">
                  <h3 class="text-primary">
                    Total Amount : Rs. {{ TotalAmount }}
                  </h3>
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="backPage"
                    color="#8950FC"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="confirmAlert"
                    :loading="SubmitFlag"
                    color="#90ee90"
                    elevation="30"
                    shaped
                    tile
                    large
                    class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >
                    Pay Now
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-container>
        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      SubmitFlag: false,
      SearchFlag: false,
      rows: [],
      tableColumns1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,

      TotalAmount: 0,

      PaymentGateway: "",
      Member: {},
      OrderConfig: {},
      ThemeConfig: {},
      MemberImageFlag: false,

      direction: "top",
      fab: true,
      fling: false,
      hover: true,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: "slide-y-reverse-transition",

      JciYearName: "",

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: 11,
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      MobileNoRules: [(v) => !!v || "Mobile No is required"],

      EmailIdRules: [(v) => !!v || "Email Id No is required"],

      QtyRules: [
        (v) => Number.isInteger(Number(v)) || "Nos is invalid",
        (v) => v >= 0 || "Number must be greater than zero",
      ],

      LomName: "",
      LomNameRules: [(v) => !!v || "LOM name is required"],

      GstNumber: "",
    };
  },
  mounted() {
    /*
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Form Inputs & Control", route: "autocompletes" },
      { title: "Fileinptus" },
    ]);
    */
  },
  watch: {
    SearchId: function () {
      console.log("watch SearchId");
      this.rows = [];
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    backPage() {
      console.log("backPage called");
      var url = "/members/subscription/payment/create";
      var params = {};
      this.pageRedirect(params, url);
    },
    addRecord() {
      console.log("addRecord called");
      var url = "/staff/list";
      var params = {};
      this.pageRedirect(params, url);
    },
    resetForm() {
      this.$refs.form2.reset();
      this.CharterDate = "";
      this.AffiliationDate = "";
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var checkout = this.$session.get("checkout");
      checkout = checkout == (null || undefined) ? {} : checkout;
      console.log({ checkout });
      this.PaymentGateway = checkout.PaymentGateway;
      this.JciYearCode = checkout.YearCode;
      this.JciYearName = checkout.YearName;
      this.GstNumber =
        checkout.GstNumber != "" && checkout.GstNumberFlag == 1
          ? checkout.GstNumber
          : "NA";
      this.Member = checkout.Member;
      this.TotalAmount = checkout.TotalAmount;
      this.OrderConfig = checkout.Order;
      this.ThemeConfig = checkout.Theme;
      this.tableColumns1 = checkout.TableHeaders;
      var Contribution = checkout.Contribution;
      this.rows = Contribution;
    },
    billdeskPayment() {
      console.log("billdeskPayment called");
      const thisIns = this;
      var responseHandler = function (txn) {
        console.log({ txn });

        var response = txn.txnResponse;
        console.log({ response });

        var message = response.hasOwnProperty("message")
          ? response.message
          : "";
        console.log({ message });

        var transactionResponse = response.hasOwnProperty(
          "transaction_response"
        )
          ? response.transaction_response
          : "";
        console.log({ transactionResponse });

        if (transactionResponse != "") {
          thisIns.$session.remove("checkout");
          thisIns.SubmitFlag = true;
          thisIns.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
          thisIns.pageRedirect({}, "/payment/success");
        } else {
          thisIns.sweetAlert("error", message, true);
          thisIns.pageRedirect({}, "/members/subscription/payment/create");
        }
      };

      var config = {
        responseHandler: responseHandler,
        // merchantLogo: "data:image/png;base64:eqwewqesddhgjdxsc==",
        flowConfig: this.OrderConfig,
        flowType: "payments",
        themeConfig: this.ThemeConfig,
      };

      window.loadBillDeskSdk(config);
    },
    razorpayPayment() {
      console.log("razorpayPayment");
      // ev.preventDefault();
      var order = this.OrderConfig;
      const thisIns = this;
      var rzp1 = new Razorpay({
        key: order.rzp_key,
        account_id: order.rzp_account_id,
        order_id: order.rzp_order_id,
        amount: order.rzp_amount,
        name: order.rzp_name,
        currency: "INR",
        description: order.rzp_description,
        image: order.rzp_image,
        theme: {
          color: order.rzp_color,
        },
        prefill: {
          name: order.customer_name,
          email: order.customer_email_id,
          contact: order.customer_mobile_no,
        },
        notes: {
          address: "",
        },
        // callback_url: "http://domain.com/#/about/" + this.order_id,
        redirect: false,
        handler: function (response) {
          console.log("razorpay_payment_id=" + response.razorpay_payment_id);
          thisIns.$session.remove("checkout");
          thisIns.SubmitFlag = true;
          thisIns.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
          thisIns.pageRedirect({}, "/payment/success");
        },
      });
      rzp1.open();
    },
    confirmAlert() {
      var validate1 = this.$refs.form2.validate();
      var TotalAmount = this.TotalAmount;
      console.log("validate1=" + validate1 + ", TotalAmount=" + TotalAmount);

      var PaymentGateway = this.PaymentGateway;
      console.log({ PaymentGateway });

      // TotalAmount=0;

      if (validate1 && TotalAmount > 0) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            if (PaymentGateway == 1) {
              this.razorpayPayment();
            } else {
              this.billdeskPayment();
            }
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (TotalAmount == 0) {
          message += "Minimum one nos required to continue. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    var CurrentYearId = this.$session.get("CurrentYearId");
    CurrentYearId = 1;
    CurrentYearId = CurrentYearId == (null || undefined) ? 0 : CurrentYearId;
    this.CurrentYearId = CurrentYearId;
    if (this.$session.has("checkout")) {
      /*
      this.tableColumns1.push(
        { text: "Description", value: "Description" },
        { text: "Subscription Fees", value: "AmountTxt" },
        { text: "Nos", value: "Qty" },
        { text: "Sub Total", value: "SubTotal" }
        // { text: "Actions", value: "actions" }
      );
      */
      this.refreshPageData();
    } else {
      this.$router.push("/access-denied");
    }
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#member-subscription-checkout {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 15px;
  }
}
</style>